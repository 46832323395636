<template>
  <div class="register">
    <van-nav-bar title="登录" z-index="999" fixed placeholder style="width: 100%">
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>
    <div class="logo-box">
      <img class="logo" :src="schoolInfo ? schoolInfo.logoUrl : ''" alt="" />
      <div class="name">{{ params.schoolName }}</div>
    </div>
    <div class="register-box">
      <span class="input-box">
        <img class="img" src="../../../assets/home/login_name.png" />
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="params.name"
          placeholder="请输入姓名"
        />
      </span>
      <span class="input-box">
        <img class="img" src="../../../assets/home/login_id.png" />
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="params.idNo"
          placeholder="请输入身份证号"
        />
      </span>
      <span class="input-box">
        <img class="img" src="../../../assets/home/login_phone.png" />
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="params.mobile"
          placeholder="请输入手机号"
        />
      </span>
      <span class="input-box">
        <img class="img" src="../../../assets/home/login_code.png" />
        <span class="line"></span>
        <van-field
          class="input-login"
          v-model="params.code"
          placeholder="请输入验证码"
          maxlength="6"
        />
        <van-count-down ref="countDown" :time="time" :auto-start="false">
          <template #default="timeData">
            <span class="time-last" v-if="timeData.seconds != 0"
              >{{ timeData.seconds }}s</span
            >
            <span class="get-code-btn" v-else @click="getCodeMethod"
              >获取验证码</span
            >
          </template>
        </van-count-down>
      </span>
      <span class="login-btn" @click="loginClick">登录</span>
    </div>
  </div>
</template>

<script>
import { CountDown, Dialog, Field, Icon, NavBar, Toast } from "vant";
import { Login_Api, GetSchoolInfo_Api, GetCode_Api } from "@/api/login";
import { setLocalStorage } from "core/utils/local-storage";
// import { stringify } from "qs";

export default {
  components: {
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [CountDown.name]: CountDown,
    [Toast.name]: Toast,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },
  props: {
    params: [Object],
  },

  data() {
    return {
      userInfo: {
        name: "",
        idNo: "",
        mobile: "",
      },
      time: 60 * 1000,
      schoolInfo: null,
    };
  },

  mounted() {
    this.$refs.countDown.start();
    this.getSchoolInfo();
  },

  methods: {
    getCodeMethod() {
      if (!this.params.mobile) {
        Toast("请输入手机号");
        return;
      }
      let that = this;
      this.$reqPost(GetCode_Api, { mobile: this.params.mobile }).then((res) => {
        if (res.code == 200) {
          that.$refs.countDown.start();
        }
      });
    },
    loginClick() {
      var params = {
        ...this.params,
      };

      this.$reqPost(Login_Api, params).then((res) => {
        if (res.code == 200) {
          setLocalStorage({
            memberInfo: res.map.member,
          });
          // this.$router.replace({ name: "home" });
          if (this.$common.classId) {
            this.$router.replace({
              name: "submitOrder",
              query: { itemId: this.$common.classId },
            });
            this.$common.classId = 0;
          } else {
            this.$router.replace({ name: "home" });
          }
        }
      });
    },

    getSchoolInfo() {
      this.$reqGet(GetSchoolInfo_Api, {}).then((res) => {
        this.schoolInfo = res.map.object;
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.van-cell {
  width: 190px;
  padding: 0;
  background-color: transparent;
}
.register-box {
  width: 100%;
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .time-last {
    text-align: right;
    color: #666;
    font-size: 14px;
    margin-right: 12px;
    width: 80px;
    display: flex;
    justify-content: flex-end;
  }
  .get-code-btn {
    color: #333333;
    font-size: 14;
    margin-right: 12px;
    width: 80px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .input-box {
    width: 343px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
  }
  .img {
    width: 20px;
    height: 20px;
    margin: 0 12px;
  }
  .line {
    width: 1px;
    height: 16px;
    background-color: #333333;
  }
  .input-login {
    margin-left: 12px;
    background-color: transparent;
  }

  .login-btn {
    width: 343px;
    height: 50px;
    background:  var(--theme-color);
    border-radius: 25px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 8px;
  }
}

.register {
  width: 100%;
  .header-img {
    width: 100%;
    height: 379px;
    z-index: -1;
  }
  .logo-box {
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      margin-top: 49px;
      width: 68px;
      height: 68px;
    }
    .name {
      margin-top: 9px;
      color: var(--theme-color);
      font-weight: 500;
      font-size: 20px;
    }
  }
}
</style>
